import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { BehaviorSubject, Observable, merge, Subscription, of, from, timer } from "rxjs";
import { map, pairwise, startWith, debounceTime, distinctUntilChanged, switchMap, filter } from "rxjs/operators";
import { FormControl, FormGroup, Validators, AsyncValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ENVIRONMENT } from "../../../../environments/environment";
import { Specialty } from "../../../classes/flow/Questionnaire/Specialty";
import { EnergyConsult } from "../../../classes/flow/request/EnergyConsult";
import { RequestStates } from "../../../classes/flow/request/RequestStates";
import { Resident } from "../../../classes/flow/session/impl/Resident";
import { Header } from "../../../components/table/Header";
import { TimeSlotsInputComponent } from "../../../components/time-slots-input/time-slots-input.component";
import { convertDate } from "../../../helpers/convertDate";
import { LocalDatePipe } from "../../../pipes/date.pipe";
import { AddressService } from "../../../services/address.service";
import { ApplicationService } from "../../../services/application.service";
import { AreaActionService } from "../../../services/area-action.service";
import { DialogService } from "../../../services/dialog.service";
import { EnergyConsultService } from "../../../services/energy-consult.service";
import { SnackbarService } from "../../../services/snackbar.service";
import { SpecialtyService } from "../../../services/specialty.service";
import { UserService } from "../../../services/user.service";
import { alphaValidator } from "../../../validators/alpha";
import { houseNumberValidator } from "../../../validators/houseNumber";
import { phoneNumberValidator } from "../../../validators/phoneNumber";
import { postalCodeValidator } from "../../../validators/postalCode";
import { specialCharValidator } from "../../../validators/specialChars";
import { trueAlphaNumeric } from "../../../validators/trueAlphaNumeric";

@Component({
  selector: "app-new-energy-consult",
  templateUrl: "./new-energy-consult.component.html",
  styleUrls: ["./new-energy-consult.component.less"],
})
export class NewEnergyConsultComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("areaRerouteDialog") areaRerouteDialog!: TemplateRef<unknown>;

  public allSpecialties: Specialty[] = [];
  public linkedSpecialties: Specialty[] = [];

  public areaActionsColumns: Header[] = [];
  public areaActionsDataFiltered: AreaActionTableData[] = [];
  public messageSearchForMore = false;
  private _areaActionsDataSubject: BehaviorSubject<AreaActionTableData[]> = new BehaviorSubject<AreaActionTableData[]>([]);
  public areaActionsData$ = this._areaActionsDataSubject.asObservable();

  public phoneNumberRequired = ENVIRONMENT.MODULES.includes("PHONE_NUMBER_REQUIRED");

  set areaActionsData(data: AreaActionTableData[]) {
    this._areaActionsDataSubject.next(data);
  }

  get areaActionsData(): AreaActionTableData[] {
    return this._areaActionsDataSubject.getValue();
  }

  public formGroup!: FormGroup;

  private usePhoneNumberSubscription?: Subscription;
  private addressAutoFillSubscription?: Subscription;
  private filterPostalCodeValues?: Subscription;
  private filterPostalCodeInput?: Subscription;
  private checkAreaByPostal?: Subscription;

  public errorMessageTracker: {
    addressFound: boolean | null;
  } = { addressFound: null };

  @ViewChild("popup") popup!: TemplateRef<unknown>;
  @ViewChild("timeSlotPicker") timeSlotPicker!: TimeSlotsInputComponent;

  public areaActionMessage: Observable<string | null>;
  private areaDataForDialog: any;

  get addressFormGroup(): FormGroup {
    return this.formGroup.get("address") as FormGroup;
  }

  public constructor(
    public localDatePipe: LocalDatePipe,
    private readonly areaActionService: AreaActionService,
    private readonly energyConsultService: EnergyConsultService,
    public readonly applicationService: ApplicationService,
    public readonly userService: UserService,
    public readonly router: Router,
    public readonly dialogService: DialogService,
    public readonly snackService: SnackbarService,
    private readonly specialtyService: SpecialtyService,
    private readonly translateService: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly addressService: AddressService
  ) {
    this.areaActionMessage = new Observable<string | null>();
  }

  async ngOnInit() {
    this.initializeForm();

    this.usePhoneNumberSubscription = this.formGroup.controls.usePhoneNumber.valueChanges.subscribe((value: boolean | null) => {
      if (value) {
        this.formGroup.controls.phoneNumber.enable({ emitEvent: false });
      } else {
        this.formGroup.controls.phoneNumber.disable({ emitEvent: false });
      }
    });

    this.addressAutoFillSubscription = merge(
      this.addressFormGroup.controls.postalCode.valueChanges,
      this.addressFormGroup.controls.houseNumber.valueChanges,
      this.addressFormGroup.controls.houseNumberSuffix.valueChanges
    )
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.autoFillAddress();
      });

    this.filterPostalCodeValues = this.addressFormGroup.controls.postalCode.valueChanges
      .pipe(
        filter((value: string | null) => {
          return value !== null && /^[1-9][0-9]{3} ?(?!SA|SD|SS)[A-Z]{2}$/i.test(value);
        })
      )
      .subscribe((value: string | null) => {
        this.filterAreaActionsDataByPostalCode(value);
      });

    this.filterPostalCodeInput = this.addressFormGroup.controls.postalCode.valueChanges.pipe(startWith(""), pairwise()).subscribe(([prevValue, newValue]) => {
      if (prevValue === newValue) return;
      this.addressFormGroup.controls.postalCode.patchValue(
        /^(?:[0-9]|$){1,4}(?:[a-z]|$){0,2}$/i.test(newValue ?? "") ? (newValue ?? "").toUpperCase() : (prevValue ?? "").toUpperCase()
      );
    });

    if (ENVIRONMENT.AREA_OF_OPERATIONS.MATCHES.length > 0) {
      this.addressFormGroup.controls.postalCode.statusChanges.pipe(distinctUntilChanged()).subscribe((status) => {
        if (status === "INVALID" && this.addressFormGroup.controls.postalCode.errors?.postalOutsideAreaScope && this.areaDataForDialog) {
          for (const envMatches of ENVIRONMENT.AREA_OF_OPERATIONS.MATCHES) {
            if (envMatches.value.includes(this.areaDataForDialog[envMatches.key]) && envMatches.dialog?.open) {
              this.dialogService.open({
                template: this.areaRerouteDialog,
                data: {
                  link: envMatches.dialog?.link,
                  custom: envMatches.dialog?.customi18n,
                },
              });
            }
          }
        }
      });
    }

    this.areaActionsData$.subscribe(() => {
      const currentPostalCode = this.addressFormGroup.controls.postalCode.value;
      this.filterAreaActionsDataByPostalCode(currentPostalCode);
    });

    if (this.applicationService.session.user instanceof Resident) {
      this.initializeFormData(this.applicationService.session.user);
    }

    await this.initForm();
    this.setAreaActionsColumns();
    await this.setAreaActionsData();
  }

  public ngAfterViewInit(): void {
    this.areaActionMessage = merge(this.addressFormGroup.controls.postalCode.valueChanges, this.timeSlotPicker.timeSlotFormGroup.valueChanges).pipe(
      startWith(null),
      map(() => this.getAreaActionMessage())
    );
  }

  private filterAreaActionsDataByPostalCode(value: string | null): void {
    if (!this.areaActionsData) {
      return;
    }

    if (value && this.areaActionsData.length > 0) {
      if (!/^[1-9][0-9]{3} ?(?!SA|SD|SS)[A-Z]{2}$/i.test(value)) {
        return;
      }

      this.areaActionsDataFiltered = [];
      const formattedValue = value.replace(" ", "").toUpperCase().slice(0, 6);

      for (let index = 0; index < this.areaActionsData.length; index++) {
        if (this.areaActionsData[index].postalCode.startsWith(formattedValue)) {
          this.areaActionsDataFiltered.push(this.areaActionsData[index]);
        }

        if (this.areaActionsDataFiltered.length > 4) {
          this.messageSearchForMore = true;
          break;
        }
      }

      if (this.areaActionsDataFiltered.length <= 4) {
        this.messageSearchForMore = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.usePhoneNumberSubscription?.unsubscribe();
    this.filterPostalCodeValues?.unsubscribe();
    this.addressAutoFillSubscription?.unsubscribe();
    this.filterPostalCodeInput?.unsubscribe();
    this.checkAreaByPostal?.unsubscribe();
  }

  private initializeForm() {
    this.formGroup = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      firstName: new FormControl("", [Validators.required, alphaValidator, Validators.maxLength(30)]),
      lastName: new FormControl("", [Validators.required, alphaValidator, Validators.maxLength(30)]),
      usePhoneNumber: new FormControl(ENVIRONMENT.MODULES.includes("PHONE_NUMBER_REQUIRED") ? true : false),
      phoneNumber: new FormControl<string | null>(
        {
          value: null,
          disabled: ENVIRONMENT.MODULES.includes("PHONE_NUMBER_REQUIRED") ? false : true,
        },
        [Validators.required, phoneNumberValidator]
      ),
      address: new FormGroup({
        postalCode: new FormControl("", {
          validators: [Validators.required, postalCodeValidator],
          asyncValidators: [this.areaOfOperationsValidator],
        }),
        houseNumber: new FormControl("", [Validators.required, houseNumberValidator]),
        houseNumberSuffix: new FormControl("", [trueAlphaNumeric, Validators.maxLength(5)]),
      }),
      streetName: new FormControl({ value: "", disabled: false }, [alphaValidator, Validators.required]),
      town: new FormControl({ value: "", disabled: false }, [alphaValidator, Validators.required]),
      specialties: new FormControl(),
      extraMessage: new FormControl("", [specialCharValidator, Validators.maxLength(250)]),
      timeSlots: new FormControl(""),
    });
  }

  /**
   *Initializes the form
   */
  public async initForm() {
    this.allSpecialties = await this.specialtyService.getSpecialties();

    //checks for specialty in the link after /request/ if is found it sets it otherwise all specialties stay
    this.getCurrentLinkedSpecialty();
    this.formGroup.patchValue({
      specialties: this.allSpecialties.find((specialty) => specialty.order == 0),
    });

    // If not one with order 0, sort by order (and place specialties without order at bottom)
    if (!this.formGroup.controls.specialties.value) {
      this.allSpecialties.sort((a, b) => {
        return (a.order ?? 1000) > (b.order ?? 1000) ? 1 : -1;
      });
      this.formGroup.patchValue({ specialties: this.allSpecialties[0] });
    }
  }

  public getCurrentLinkedSpecialty() {
    const linkedspecialtyString = this.route.snapshot.paramMap.get("specialty") ?? "";
    if (linkedspecialtyString == "energiehulp") {
      const specialty = this.allSpecialties.filter((specialty) => specialty.id == 12);
      if (specialty.length > 0) {
        this.allSpecialties = specialty;
      }
    }

    //MIGHT BE USEFULL
    // FOR WHEN YOU WANT TO CHECK THE LINK FOR THE SPECIALTY NAME
    // this.allSpecialties.forEach((element) => {
    //   if (element.name == linkedspecialtyString) {
    //     this.allSpecialties = [element];
    //   }
    // });
  }

  /**
   * Saves a new form
   */
  public async saveNewEnergyConsultForm() {
    this.applicationService.setLoading(true);
    try {
      if (!this.formGroup.valid) throw new Error("Invalid form");

      const data = this.formGroup.value;
      this.formGroup.disable();
      if (ENVIRONMENT.MODULES.includes("TIME_SLOTS")) this.timeSlotPicker.disableForm();
      const resident: Resident = new Resident({
        id: 0,
        email: data.email!,
        firstName: data.firstName!,
        lastName: data.lastName!,
        phoneNumber: data.usePhoneNumber && data.phoneNumber ? data.phoneNumber : null,
      });

      const extraProperties: NonNullable<EnergyConsult["extraProperties"]> = {};
      extraProperties.preferredTimeSlots = data.timeSlots ? JSON.parse(data.timeSlots) : undefined;
      extraProperties.streetname = data.streetName ?? undefined;
      extraProperties.addressdetails = data.town ? { town: data.town } : undefined;

      const energyConsult: EnergyConsult = new EnergyConsult({
        id: 0,
        message: data.extraMessage ?? "",
        postalCode: data.address!.postalCode!,
        houseNumber: Number(data.address!.houseNumber!),
        requestDate: new Date(),
        state: { name: RequestStates.NEW },
        specialty: data.specialties,
        resident: resident,
        houseNumberSuffix: data.address!.houseNumberSuffix!,
        extraProperties: extraProperties,
      });
      const result = await this.energyConsultService.create(energyConsult);
      if (result.data.addEnergyConsult.value?.id) {
        this.snackService.open(this.translateService.instant("FORMS.NEW_ENERGY_CONSULT.SUCCESS_MESSAGE"));
        this.formGroup.reset();

        if (ENVIRONMENT.MODULES.includes("TIME_SLOTS")) this.timeSlotPicker.clearForm();
        if (this.applicationService.session.authenticated) {
          this.applicationService.session.user = await this.userService.initialize(this.applicationService.session.activeRole);
          this.router.navigate(["/content"]);
        } else {
          this.openSuccessDialog();
        }
      } else {
        const error = new Error(result.data.addEnergyConsult.messages[0].message);
        error.name = result.data.addEnergyConsult.messages[0].number.toString();
        throw error;
      }
    } catch (error) {
      if (error instanceof Error && error.name == "10024") {
        this.snackService.open(error.message, "fout");
      } else {
        this.snackService.error();
      }
      this.formGroup.enable();
      if (ENVIRONMENT.MODULES.includes("TIME_SLOTS")) this.timeSlotPicker.enableForm();
      if (this.formGroup.controls.usePhoneNumber.value == false) this.formGroup.controls.phoneNumber.disable();
    }
    this.applicationService.setLoading(false);
  }

  /**
   * Opens the success dialog
   */
  openSuccessDialog() {
    this.dialogService.open({
      template: this.popup,
    });
  }

  /**
   * Set columns for the available area actions
   */
  setAreaActionsColumns() {
    this.areaActionsColumns = [
      { visualName: "POSTALCODE", mappedTo: "postalCode" },
      { visualName: "DATE_START", resolver: this.startDateToString },
      { visualName: "DATE_END", resolver: this.endDateToString },
      { visualName: "AREA", mappedTo: "nameArea" },
      { visualName: "MUNICIPALITY", mappedTo: "nameMunicipality" },
    ];
  }

  async setAreaActionsData() {
    const areaActionRange = await this.areaActionService.all(true, true);

    const results: AreaActionTableData[] = [];

    areaActionRange.forEach((range) => {
      const startZip = this.extractZipParts(range.zipCodeFrom);
      const endZip = this.extractZipParts(range.zipCodeTo);
      const dateStart = new Date(range.dateFrom);
      const dateEnd = new Date(range.dateTo);
      const nameArea = range.area!;
      const nameMunicipality = range.municipality!;

      for (let preZip = startZip.number; preZip <= endZip.number; preZip++) {
        const firstLetterIndexStart = preZip === startZip.number ? startZip.firstChar : 0;
        const firstLetterIndexEnd = preZip === endZip.number ? endZip.firstChar : 25;

        for (let firstLetter = firstLetterIndexStart; firstLetter <= firstLetterIndexEnd; firstLetter++) {
          const secondLetterIndexStart = preZip === startZip.number && firstLetter === startZip.firstChar ? startZip.secondChar : 0;
          const secondLetterIndexEnd = preZip === endZip.number && firstLetter === endZip.firstChar ? endZip.secondChar : 25;

          for (let secondLetter = secondLetterIndexStart; secondLetter <= secondLetterIndexEnd; secondLetter++) {
            const postalCode = this.constructPostalCode(preZip, firstLetter, secondLetter);
            results.push({
              postalCode,
              dateStart,
              dateEnd,
              nameArea,
              nameMunicipality,
            });
          }
        }
      }
    });

    this.areaActionsData = this.areaActionsData.concat(results);
  }

  extractZipParts(zipCode: string) {
    const formattedZip = zipCode.replace(/\s/g, "").toUpperCase();
    return {
      number: Number(formattedZip.slice(0, 4)),
      firstChar: this.alphaToNumber(formattedZip[4]),
      secondChar: this.alphaToNumber(formattedZip[5]),
    };
  }

  constructPostalCode(number: number, firstChar: number, secondChar: number): string {
    return number.toString() + this.numberToAlpha(firstChar) + this.numberToAlpha(secondChar);
  }

  alphaToNumber(char: string): number {
    return "ABCDEFGHIJKLMNOPQRSTUVWXYZ".indexOf(char.toUpperCase());
  }

  numberToAlpha(char: number): string {
    return "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[char];
  }

  startDateToString: (areaAction: AreaActionTableData) => string = (areaAction: AreaActionTableData) => {
    return this.localDatePipe.transform(convertDate(areaAction.dateStart!), "d MMMM y");
  };

  endDateToString: (areaAction: AreaActionTableData) => string = (areaAction: AreaActionTableData) => {
    return this.localDatePipe.transform(convertDate(areaAction.dateEnd!), "d MMMM y");
  };

  /**
   * Initializes the form
   * @param {Resident} user - the resident which the form gets data from
   */
  public initializeFormData(user: Resident) {
    this.formGroup.controls.email.patchValue(user.email);
    this.formGroup.controls.firstName.patchValue(user.firstName ?? null);
    this.formGroup.controls.lastName.patchValue(user.lastName ?? null);
    this.formGroup.controls.usePhoneNumber.patchValue(!!user.phoneNumber);
    this.formGroup.controls.phoneNumber.patchValue(user.phoneNumber);
    this.addressFormGroup.controls.houseNumber.patchValue(user.houseNumber ? String(user.houseNumber) : null);
    this.addressFormGroup.controls.postalCode.patchValue(user.postalCode ?? null);
    this.addressFormGroup.controls.houseNumberSuffix.patchValue(user.houseNumberSuffix ?? null);

    if (user.phoneNumber) {
      this.formGroup.controls.phoneNumber.enable();
    }
  }

  public getAreaActionMessage(): string {
    const defaultMessage = "AREAACTIONS.MESSAGES.DEFAULT";
    const successMessage = "AREAACTIONS.MESSAGES.INSIDE";
    const warningMessage = "AREAACTIONS.MESSAGES.OUTSIDE";

    if (!this.timeSlotPicker) return defaultMessage;

    if (!this.addressFormGroup.controls.postalCode!.valid || !this.addressFormGroup.controls.postalCode.value) return defaultMessage;
    if (!this.timeSlotPicker.timeSlotFormGroup.controls.date.valid || !this.timeSlotPicker.timeSlotFormGroup.controls.date.value) return defaultMessage;

    const date: Date = this.timeSlotPicker.timeSlotFormGroup.controls["date"].value!;

    const postalCodeActions = this.areaActionsData.filter(
      (action) => this.formatPostalCode(action.postalCode) === this.formatPostalCode(this.addressFormGroup.controls.postalCode.value!)
    );

    const postalCodeAndDateMatches = postalCodeActions.filter((action) => action.dateStart && action.dateEnd && this.dateIsBetween(action.dateStart, action.dateEnd, date));

    if (postalCodeAndDateMatches.length) return successMessage;
    if (postalCodeActions.length) return warningMessage;
    return defaultMessage;
  }

  public dateIsBetween(startDate: Date, endDate: Date, date: Date): boolean {
    return date >= startDate && date <= endDate;
  }

  public formatPostalCode(postal: string) {
    return postal.toUpperCase().replaceAll(" ", "");
  }

  private async autoFillAddress() {
    const addressCtrls = this.addressFormGroup.controls;
    const streetNameCtrl = this.formGroup.controls.streetName;
    const townCtrl = this.formGroup.controls.town;

    const postalCode = addressCtrls.postalCode.value;
    const houseNumber = addressCtrls.houseNumber.value;
    const houseNumberSuffix = addressCtrls.houseNumberSuffix.value;

    if (!postalCode || !houseNumber) {
      this.errorMessageTracker.addressFound = null;
      return;
    }

    if (!/^[1-9][0-9]{3} ?(?!SA|SD|SS)[A-Z]{2}$/i.test(postalCode)) {
      this.errorMessageTracker.addressFound = null;
      return;
    }

    const address = await this.addressService.lookup(postalCode, houseNumber, houseNumberSuffix);

    if (address === null) {
      this.errorMessageTracker.addressFound = false;
    } else {
      this.errorMessageTracker.addressFound = true;
    }

    streetNameCtrl.patchValue(null, { emitEvent: false });
    townCtrl.patchValue(null, { emitEvent: false });

    streetNameCtrl.enable({ emitEvent: false });
    townCtrl.enable({ emitEvent: false });

    if (address) {
      streetNameCtrl.patchValue(address.streetName, { emitEvent: false });
      townCtrl.patchValue(address.town, { emitEvent: false });
    } else {
      streetNameCtrl.setErrors({ streetNameNotFound: true }, { emitEvent: false });
      townCtrl.setErrors({ townNotFound: true }, { emitEvent: false });
    }

    streetNameCtrl.markAsTouched();
    townCtrl.markAsTouched();
  }
  //TODO move to validators
  private areaOfOperationsValidator: AsyncValidatorFn = (control: AbstractControl): Observable<ValidationErrors | null> => {
    const value = control.value;

    return timer(500).pipe(
      switchMap(() => {
        if (value === null || !/^[1-9][0-9]{3} ?(?!SA|SD|SS)[A-Z]{2}$/i.test(value)) {
          return of(null);
        }

        return from(this.addressService.lookupAreaByPostal(value)).pipe(
          map((area) => {
            return this.validateArea(area);
          })
        );
      })
    );
  };

  private validateArea(area: any): ValidationErrors | null {
    if (area.gemeentenaam === null && area.provincienaam === null) {
      return { postalCodeNotFound: true };
    }

    const match = { include: false, exclude: false };
    for (const envMatches of ENVIRONMENT.AREA_OF_OPERATIONS.MATCHES) {
      const areaValue = area[envMatches.key];
      if (envMatches.type === "include" && areaValue && envMatches.value.includes(areaValue)) {
        match.include = true;
      }
      if (envMatches.type === "exclude" && areaValue && envMatches.value.includes(areaValue)) {
        match.exclude = true;
      }
    }

    this.areaDataForDialog = area;

    if (!(match.include && !match.exclude)) {
      return { postalOutsideAreaScope: true };
    }

    return null;
  }
}

interface AreaActionTableData {
  postalCode: string;
  dateStart?: Date;
  dateEnd?: Date;
  nameArea: string;
  nameMunicipality: string;
}
